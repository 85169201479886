import React, { useState, useEffect } from "react";
import { Container, Row, Stack, Col } from "react-bootstrap";
import Pagination from 'react-bootstrap/Pagination';
import ReactGA from 'react-ga';

const Post = ({ title, date, onClick }) => {
    return (
        <>
            <Container fluid="xxl" className="my-2" style={{ cursor: "pointer" }} onClick={onClick}>
                <Stack gap={3} className="justify-content-evenly">
                    <div className="icon" style={{ width: "100%" }}>
                        <h5 className="post-title">{title}</h5>
                        <h5 className="post-date">{new Date(date).toLocaleDateString()}</h5>
                    </div>
                </Stack>
                <hr className="custom-hr" />
            </Container>
        </>
    )
}

const Pages = ({ currentPage, totalPages, nextPage, prevPage, onPageChange }) => {
    return (
        <Pagination className="custom-pagination" data-bs-theme="dark">
            <Pagination.First onClick={() => onPageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => onPageChange(currentPage - 1)} disabled={!prevPage} />
            {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => onPageChange(index + 1)}>
                    {index + 1}
                </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => onPageChange(currentPage + 1)} disabled={!nextPage} />
            <Pagination.Last onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
    );
}

const Blog = () => {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [nextPage, setNextPage] = useState(null);
    const [prevPage, setPrevPage] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchPosts = async (page = 1) => {
        try {
            const response = await fetch(`/blog/api/posts/?page=${page}`);
            const data = await response.json();
            setLoading(false);
            setPosts(data.results);
            setTotalPages(Math.ceil(data.count / 5));
            setNextPage(data.next);
            setPrevPage(data.previous);
            setCurrentPage(page);
        } catch (error) {
            console.error('Error fetching blog posts:', error);
        }
    };

    useEffect(() => {
        fetchPosts();
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const handlePageChange = (page) => {
        fetchPosts(page);
    };

    return (
        <>
            <Container fluid="xxl" className="fade-in">
                <Container style={{ height: "10vh" }} />
                <Row>
                    <Col>
                        <h1 className="p-3 pb-5">Blog</h1>
                    </Col>
                </Row>
                <Row>
                    <Stack style={{ justifyContent: "center"}}>
                        { !loading && posts.map(post => (
                            <Post
                                onClick={() => window.open('/blog/view/#' + post.uuid, '_self')}
                                title={post.title}
                                date={post.created_at}
                            />
                        )) }
                        { loading && <div className="loader fade-in my-5" style={{float: "right"}} /> }
                    </Stack>
                    <div className="d-flex justify-content-center">
                        <Pages
                            currentPage={currentPage}
                            totalPages={totalPages}
                            nextPage={nextPage}
                            prevPage={prevPage}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </Row>
                <Container style={{ height: "10vh" }} />
            </Container>
        </>
    )
}

export default Blog;