import React from "react";

const Footer = () => {

    return (
        <p className="footer fade-in">
            © 2024
            <a href="https://zheidemann.dev">Zac Heidemann</a>
            ·
            <a href="https://creativecommons.org/licenses/by-nc/4.0/" target="_blank" rel="noreferrer">CC BY-NC 4.0</a>
        </p>
    )
}

export default Footer;