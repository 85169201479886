import React, { useState, useEffect } from "react";
import ReactGA from 'react-ga';
import { Stack, Container } from "react-bootstrap";
import { marked } from "marked";
import DOMPurify from "dompurify";

const CalendarIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
        </svg>
    )
}

const BlogPage = () => {
    const [content, setContent] = useState("");
    const [title, setTitle] = useState("");
    const [createdDate, setCreatedDate] = useState("");
    const [updatedDate, setUpdatedDate] = useState("");
    const [loading, setLoading] = useState(true);

    const fetchContent = async () => {
        const uuid = window.location.hash.substring(1); // Get the UUID from the URL
        try {
            const response = await fetch("/blog/api/posts/" + uuid);
            const data = await response.json();
            setLoading(false);
            setContent(data.content);
            setTitle(data.title);
            setCreatedDate(data.created_at);
            setUpdatedDate(data.updated_at);
        } catch (error) {
            console.error('Error fetching blog content:', error);
        }
    }

    useEffect(() => {
        fetchContent();
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const sanitizedContent = DOMPurify.sanitize(marked(content));
    

    return (
        <>
            <Stack className="justify-content-center" id="blog-page">
                <Container fluid="xxl" style={{ maxWidth: "800px" }}>
                    <div>
                        <h1 className="p-1 pb-0 mt-5">{title}</h1>
                        <p
                            className="mb-5 pb-5"
                            style={{ fontSize: "0.6em" }}
                        >
                            <CalendarIcon />
                            {" "}
                            ·
                            {new Date(createdDate).toLocaleDateString()}
                        </p>
                    </div>
                    { !loading && <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />}
                    { loading && <div className="loader fade-in my-5" />}
                </Container>
                <Container fluid="xxl" style={{ maxWidth: "800px" }}>
                    <Stack className="my-2">
                        <hr />
                        <h5 className="post-date">
                            <CalendarIcon />
                            {" "}
                            ·
                            {createdDate}
                            , <b>updated</b> {updatedDate}
                        </h5>
                    </Stack>
                </Container>
            </Stack>
        </>
    )
}

export default BlogPage;