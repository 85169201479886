import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import NotFound from './components/NotFound';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element:  <App view={"home"} />,
    errorElement: <NotFound />
  },
  {
    path: "/blog",
    element:  <App view={"blog"} />,
    errorElement: <NotFound />
  },
  {
    path: "/about",
    element:  <App view={"about"} />,
    errorElement: <NotFound />
  },
  {
    path: "/blog/view",
    element: <App view={"blogpage"} />,
    errorElement: <NotFound />
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
const html = document.getElementsByTagName('html')
html[0].className = "scrollbar"
html[0].style = "background-color: #1B1B1D"

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
