import React, { useEffect } from "react";
import { Stack } from "react-bootstrap";
import LinkedInIcon from "./LinkedInIcon";
import GitHubIcon from "./GitHubIcon";
import ReactGA from 'react-ga';


const Landing = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    
    return (
        <>
            <Stack id="landing" className="text-center fade-in">
                <h1>/zheidemann.dev</h1>
                <p><i>I make things work sometimes</i></p>
                <div>
                    <Stack direction="horizontal" gap={3}>
                        <LinkedInIcon width="32" height="32" fill="white" />
                        <GitHubIcon width="32" height="32" fill="white" />
                    </Stack>
                </div>
                <div className="mt-3">
                    <Stack direction="vertical" gap={1}>
                        <a className="m-1" href="/blog">Blog</a>
                        <a className="m-1" href="/about">About</a>
                    </Stack>
                </div>
            </Stack>
        </>
    );
}

export default Landing;