import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Stack from 'react-bootstrap/Stack';
import LinkedInIcon from './LinkedInIcon';
import GitHubIcon from './GitHubIcon';

function NavBar() {
    return (
        <>
            <Navbar
                data-bs-theme="dark"
                expand="md"
                style={{
                    backgroundColor: "#141619",
                    borderTop: "solid",
                    borderColor: "rgba(255, 255, 255, 0.5)",
                    borderWidth: "1px",
                    position: "sticky",
                    bottom: "0",
                }}>
                <Container>
                    <Navbar.Brand id="nav-brand" className="me-3" href="/">/zheidemann.dev</Navbar.Brand>
                    <Navbar.Toggle style={{ border: "none", color: "white"}} />
                    <Navbar.Collapse className="justify-content-end">
                        <Nav className="me-auto">
                            <Nav.Link id="nav-link" className="mx-1" href="/blog">Blog</Nav.Link>
                            <Nav.Link id="nav-link" className="mx-1" href="/about">About</Nav.Link>
                        </Nav>
                        <Stack direction="horizontal" gap={3}>
                            <LinkedInIcon width="32" height="32" fill="white" />
                            <GitHubIcon width="32" height="32" fill="white" />
                        </Stack>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default NavBar;