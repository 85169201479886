import './App.css';
import Landing from './components/Landing';
import Blog from './components/Blog';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import About from './components/About';
import BlogPage from './components/BlogPage';
import ReactGA from 'react-ga';

ReactGA.initialize('G-C0TECP20EK');
ReactGA.pageview(window.location.pathname + window.location.search);

function App(props) {
  return (
    <div className="App" style={{ backgroundColor: "#141619" }}>
      {props.view === "home" && <Landing />}
      {props.view === "blog" && <Blog />}
      {props.view === "about" && <About />}
      {props.view === "blogpage" && <BlogPage />}

      {props.view !== "home" && <NavBar />}
      {props.view === "home" && <Footer />}
    </div>
  );
}

export default App;
