import React from "react";
import { Stack } from "react-bootstrap";
import NavBar from "./NavBar";

const NotFound = () => {

    return (
    <>
        <Stack id="notfound" className="text-center fade-in">
            <h1 style={{ fontSize: "6em" }}>404</h1>
            <p><i>What are you looking for?</i></p>
        </Stack>
        <NavBar />
    </>
    );
}

export default NotFound;