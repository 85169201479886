import React, { useEffect } from "react";
import { Container, Stack } from "react-bootstrap";
import ReactGA from 'react-ga';

const About = () => {
    
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <>
            <Stack id="about" className="fade-in">
                <Container fluid="xxl" style={{ maxWidth: "800px"}}>
                    <h1>About</h1>
                    <p>
                        Hey I'm Zac!
                        <br />
                        <br />
                        Welcome to my corner of the internet! I'm a software engineer with a passion for building things.
                        <br />
                        <br />
                        I'm currently working at Yelp as a Full Stack Software Engineer. I graduated from Chandler-Gilbert Community College 
                        with an Associate of Science in Computer Science and am currently pursuing a Bachelor of Science in Computer Science
                        at Arizona State University.
                        <br />
                        <br />
                        I'm always looking to learn new things and improve my skills. I'm currently learning about web development and
                        am working on a few projects to help me learn more about it. You'll see some of those projects on this site.
                        <br />
                        <br />
                        If you'd like to get in touch then send me a message on LinkedIn. I'd love to hear from you!

                    </p>
                </Container>
            </Stack>
        </>

    )
}

export default About;